import withComponentName from '../../../decorators/withComponentName';

import Styles from '../styles.module.scss';

const MeetupVisitSection = () => (
  <div className={Styles['meetup-visit-section']}>
    <div className={Styles.section}>
      <div className={Styles['map-section']}>
        <h2>Visit a meetup in your city</h2>
        <div className={Styles['country-section']}>
          <span className={Styles.name}>United Kingdom</span>
          <div className={Styles.cities}>
            <a href="https://www.meetup.com/london-investing/" target="_blank" rel="noreferrer">London</a>
            <a href="https://www.meetup.com/Manchester-Value-Investing-Club/" target="_blank" rel="noreferrer">Manchester</a>
            <a href="https://www.meetup.com/Southampton-Value-Investing-Club/" target="_blank" rel="noreferrer">Southampton</a>
            <a href="https://www.meetup.com/Birmingham-Investing/" target="_blank" rel="noreferrer">Birmingham</a>
            <a href="https://www.meetup.com/bristol-investing/" target="_blank" rel="noreferrer">Bristol</a>
            <a href="https://www.meetup.com/Oxford-investing/" target="_blank" rel="noreferrer">Oxford</a>
            <a href="https://www.meetup.com/Leeds-Value-Investing-Club/" target="_blank" rel="noreferrer">Leeds</a>
            <a href="https://www.meetup.com/milton-keynes-investing/" target="_blank" rel="noreferrer">Milton Keynes</a>
            <a href="https://www.meetup.com/cambridge-investing/" target="_blank" rel="noreferrer">Cambridge</a>
          </div>
        </div>
        <div className={Styles['double-country-section']}>
          <div className={Styles['country-section']}>
            <span className={Styles.name}>France</span>
            <div className={Styles.cities}>
              <a href="https://www.meetup.com/paris-investing/" target="_blank" rel="noreferrer">Paris</a>
            </div>
          </div>
          <div className={Styles['country-section']}>
            <span className={Styles.name}>Belgium</span>
            <div className={Styles.cities}>
              <a href="https://www.meetup.com/brussels-investing/" target="_blank" rel="noreferrer">Brussels</a>
            </div>
          </div>
        </div>
        <div className={Styles['double-country-section']}>
          <div className={Styles['country-section']}>
            <span className={Styles.name}>Singapore</span>
            <div className={Styles.cities}>
              <a href="https://www.meetup.com/Value-Investing/" target="_blank" rel="noreferrer">Singapore</a>
            </div>
          </div>
          <div className={Styles['country-section']}>
            <span className={Styles.name}>malta</span>
            <div className={Styles.cities}>
              <a href="https://www.meetup.com/en-AU/Malta-Value-Investing-Club/" target="_blank" rel="noreferrer">Valletta</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default withComponentName(MeetupVisitSection);
